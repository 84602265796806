import React from "react"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageWrapper } from '../components/PageWrapper';
import { Section } from "../components/Section";
import { StickyInfo } from "../components/StickyInfo";
import { StyledHeader } from "../components/Elements";

export default function PageTemplate({ data: { mdx, allMdx } }) {

  const keywords = mdx.frontmatter.keywords[0].split(',')
  const getRecommendation = allMdx.edges.map((article) => {
    const reccCount = keywords.reduce((acc, curr) => {
      if (article.node.frontmatter.keywords[0].includes(curr)) {
        return acc + 1
      }
      return acc;
    }, 0)

    return {
      ...article,
      reccomendation: reccCount
    };
  }).sort((a, b) => b.reccomendation - a.reccomendation)

  const reccomendation = getRecommendation.find(recc => recc.node.frontmatter.slug !== mdx.frontmatter.slug)

  return (
    <PageWrapper seo={{
      title: mdx.frontmatter.title,
      description: mdx.frontmatter.excerpt,
      keywords: mdx.frontmatter.keywords,
      thumbnail: mdx.frontmatter.thumbnail,
      path: `/blog/${mdx.frontmatter.slug}`
    }}>
        <Section spacing={['mt-2']}>
            <StickyInfo left="01 - Article" />
            <StyledHeader>
                <h1 className="display">
                  {mdx.frontmatter.title}
                </h1>
                <div className="right font-size md">
                  {mdx.frontmatter.excerpt}
                </div>
            </StyledHeader>
        </Section>
        <Section spacing={['m-0']}>
            <img width="100%" className="no-border" src={mdx.frontmatter.thumbnail} />
        </Section>
        <MDXProvider>
            <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
        {reccomendation && (
          <Section spacing={['mt-2']}>
            <StickyInfo left="02 - Suggestion" href={`/blog/${reccomendation.node.frontmatter.slug}`} />
            <StyledHeader>
                <a href={`/blog/${reccomendation.node.frontmatter.slug}`}>
                  <h1 className="display">
                      {reccomendation.node.frontmatter.title}
                  </h1>
                </a>
                <div className="right font-size md">
                </div>
            </StyledHeader>
          </Section>
        )}
    </PageWrapper>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        author
        excerpt
        thumbnail
        slug
        keywords
      }
    }
    allMdx {
      edges {
        node {
          id
          frontmatter {
            slug
            keywords
            thumbnail
            title
          }
        }
      }
    }
  }
`